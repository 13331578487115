import { Fragment, useState, useEffect } from 'react'
import {
  Mesh,
  Scene,
  OrthographicCamera,
  WebGLRenderer,
  SphereGeometry,
  MeshBasicMaterial,
  TextureLoader,
  sRGBEncoding,
  EquirectangularReflectionMapping,
} from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { Component, Span } from './flags'

export const random = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// variables
const { innerWidth: width, innerHeight: height } = window
const target_width = width <= 700 ? 40 : 100
const max_image_id = 38

const hue = 150
const color = `hsl(${hue}, 90%, 70%)`

const scene = new Scene()

const renderer = new WebGLRenderer({ alpha: true, antialias: true })
renderer.setSize(width, height)
renderer.setClearAlpha(0)
renderer.outputEncoding = sRGBEncoding
const render = () => renderer.render(scene, camera)

const view_frustrum = [width / -8, width / 8, height / 8, height / -8]
const camera = new OrthographicCamera(...view_frustrum, 1, 1000)
camera.position.set(235, -300, -135)

// const light_1 = new DirectionalLight(0xffffff)
// light_1.position.set(50, 25, -400)
// scene.add(light_1)
// const light_2 = new DirectionalLight(0xffffff)
// light_2.position.set(100, 50, 400)
// scene.add(light_2)

const controls = new OrbitControls(camera, renderer.domElement)
controls.addEventListener('change', render) // call this only in static scenes (i.e., if there is no animation loop)

const texture = new TextureLoader().load('/images/env.png')
texture.mapping = EquirectangularReflectionMapping
texture.encoding = sRGBEncoding

export const Home = () => {
  const [scene_ref, set_scene_ref] = useState(null)
  const [canvas, set_canvas] = useState(null)
  const [image_id, set_image_id] = useState(1)
  const [green_percentage, set_green_percentage] = useState(0)
  const [loading, set_loading] = useState(false)

  const draw = () => {
    set_loading(true)

    // clear existing meshes before drawing - iterating backwards
    // to keep the indexes as we remove elements from the array
    for (let i = scene.children.length - 1; i >= 0; i--) {
      if (scene.children[i].type !== 'Mesh') continue
      scene.children[i].geometry.dispose()
      scene.children[i].material.dispose()
      scene.remove(scene.children[i])
    }

    const context = canvas.getContext('2d', { willReadFrequently: true })
    const img = new Image()

    img.onload = () => {
      const ratio = img.height / img.width
      const target_height = Math.floor(target_width * ratio)
      const img_dimensions = [0, 0, img.width, img.height]
      const target_dimensions = [0, 0, target_width, target_height]
      canvas.width = target_width
      canvas.height = target_height
      context.drawImage(img, ...img_dimensions, ...target_dimensions)
      const pixels_data = context.getImageData(...target_dimensions).data

      const pixel_values = 4
      const pixels_in_line = target_width
      const line_size = pixel_values * pixels_in_line
      const lines_amount = pixels_data.length / line_size
      const pixels_amount = pixels_data.length / pixel_values
      let green_pixels_amount = 0

      for (let line_index = 0; line_index < lines_amount; line_index++) {
        const start_index = line_index * line_size
        const line = pixels_data.slice(start_index, start_index + line_size)

        for (let pixel_index = 0; pixel_index < pixels_in_line; pixel_index++) {
          const start_index = pixel_index * pixel_values
          const rgba = line.slice(start_index, start_index + pixel_values)
          const [red, green, blue] = rgba
          const is_green = matches_green_hue_range(red, green, blue)

          if (is_green) {
            const grey = Math.floor((red + green + blue) / 3)
            const geometry = new SphereGeometry(0.6)
            // const hue = Math.floor((125 * green) / 255 + 45)
            const luminosity = (grey * 100) / 255
            const hsl = `hsl(${hue}, 90%, ${luminosity}%)`
            const material = new MeshBasicMaterial({
              color: hsl,
              // metalness: 1,
              // roughness: 0,
              // envMap: texture,
            })

            const mesh = new Mesh(geometry, material)
            mesh.position.x = -(pixel_index - target_width / 2)
            mesh.position.y = -(line_index - lines_amount / 2)
            mesh.position.z = -(green / 2)
            mesh.userData = { hue, luminosity }
            scene.add(mesh)

            green_pixels_amount++
          }
        }
      }

      render()

      const green_percentage = (green_pixels_amount / pixels_amount) * 100
      set_green_percentage(Math.round(green_percentage))
      set_loading(false)
    }

    img.src = `/images/${image_id}.jpeg`
  }

  useEffect(() => {
    if (!canvas) return
    draw(image_id)
  }, [canvas, image_id])

  useEffect(() => {
    if (!scene_ref) return
    scene_ref.appendChild(renderer.domElement)
  }, [scene_ref])

  return (
    <Page>
      <canvas ref={set_canvas} style={{ display: 'none' }} />
      <Three elemRef={set_scene_ref} />
      {loading && (
        <LoaderWrapper>
          <Loader id="loader" />
          Rendering 3D scene
        </LoaderWrapper>
      )}
      <Settings
        three={{ scene, camera, controls, render }}
        image_id={image_id}
        set_image_id={set_image_id}
        green_percentage={green_percentage}
        background={color}
      />
    </Page>
  )
}

const Page = Component.w100vw.h100vh.div()

const Arrow = ({ up }) => (
  <svg
    width={20}
    style={{ transform: up ? 'rotate(180deg)' : '' }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130 130"
  >
    <path
      fill="none"
      stroke="black"
      strokeWidth={6}
      d="M105.27 126.5H5V26.23M126 5.5 5.7 125.8"
    />
  </svg>
)

const Settings = ({
  three,
  image_id,
  set_image_id,
  green_percentage,
  background,
}) => {
  const { scene, camera, controls, render } = three
  const [is_open, set_is_open] = useState(true)

  return (
    <Fragment>
      <Control t50 r50 c_pointer onClick={() => set_is_open(!is_open)}>
        <Arrow up={is_open} />
      </Control>
      {is_open && (
        <Fragment>
          <Control t50 t30__xs l50 l30__xs pr50 pr30__xs>
            anamorphosis is a web-based app that extracts the green pixels of an
            image and translates them to reflective spheres dispatched in a 3
            dimensional space. <br />
            If you set the camera point of view exactly in front of the scene,
            you see the anamorphosis of the image, which is seen as flat.
          </Control>

          <Control c_pointer l50 l30__xs style={{ top: 180 }}>
            <Label
              style={{ background }}
              onClick={() => {
                camera.position.set(0, 0, -400)
                controls.update()
              }}
            >
              — click <Span bb>here</Span> to set anamorphosis POV
            </Label>
          </Control>

          <Control l50 l30__xs style={{ top: 220 }}>
            <Label style={{ background }}>
              — drag & drop to rotate the scene
              <br />— wheel to zoom in & out the scene
            </Label>
          </Control>

          <Control c_pointer l50 l30__xs style={{ bottom: 150 }}>
            <Label
              style={{ background }}
              onClick={() => document.body.classList.toggle('inverted')}
            >
              — click <Span bb>here</Span> to invert background
            </Label>
          </Control>

          <Control ai_center l50 l30__xs style={{ bottom: 100 }}>
            <Label style={{ background }}>— set saturation</Label>
            <Input
              type="range"
              min={0}
              max={100}
              defaultValue={90}
              onInput={(event) => {
                scene.traverse((object) => {
                  if (!object.isMesh) return
                  const { hue, luminosity } = object.userData
                  const saturation = Number(event.target.value)
                  const color = `hsl(${hue}, ${saturation}%, ${luminosity}%)`
                  object.material.color.set(color)
                })
                render()
              }}
            />
          </Control>

          <Control
            text_right
            flex_column
            ai_flex_end
            r50
            r30__xs
            style={{ top: 285 }}
          >
            <Label style={{ background }}>
              — source image <br />[ {green_percentage}% green ]
            </Label>
            <Pic src={`/images/${image_id}.jpeg`} />
          </Control>

          <Control c_pointer r50 r30__xs style={{ bottom: 225 }}>
            <Label
              style={{ background }}
              onClick={() => {
                const next_id = image_id === max_image_id ? 1 : image_id + 1
                set_image_id(next_id)
              }}
            >
              next image >>>
            </Label>
          </Control>

          <Control ai_flex_end flex_column b50 r50 b30__xs r30__xs>
            <Link target="_blank" href="https://marie.studiodev.xyz/">
              marie.studiodev.xyz
            </Link>
            <Link
              target="_blank"
              href="https://www.instagram.com/mariemalarme/"
            >
              @mariemalarme
            </Link>
          </Control>
        </Fragment>
      )}
    </Fragment>
  )
}

// functions
const rgb_to_hsv = (red, green, blue) => {
  const min = Math.min(red, green, blue)
  const max = Math.max(red, green, blue)
  const delta = max - min

  let hue = 0
  let saturation = delta / max

  if (red === max) {
    hue = (green - blue) / delta
  } else if (green === max) {
    hue = 2 + (blue - red) / delta
  } else {
    hue = 4 + (red - green) / delta
  }

  hue /= 6

  if (hue < 0) {
    hue += 1
  }

  return {
    hue: Math.round(hue * 360),
    saturation: Math.round(saturation * 100),
    value: Math.round((max / 255) * 100),
  }
}

const matches_green_hue_range = (red, green, blue) => {
  const { hue } = rgb_to_hsv(red, green, blue)
  const matches_green_hue_range = hue >= 45 && hue <= 170
  return matches_green_hue_range
}

// components
const Three = Component.main()
const Control = Component.fixed.flex.div()
const Label = Component.ba0.label.div()
const Pic = Component.w100.w70__xs.mt10.img()
const Input = Component.w100.ml20.input()
const Link = Component.black.a()
const LoaderWrapper =
  Component.zi10.t0.fixed.w100vw.h100vh.flex.flex_column.ai_center.jc_center.div()
const Loader = Component.mb20.b_rad50p.w100.h100.div()
export default Home
