import { atomizify, flagify } from 'atomizify'

atomizify({
  media_queries: {
    __xs: {
      query: 'max-width: 700px',
      description: 'small screens',
    },
  },
})
export const { Component, Div, Span } = flagify()
